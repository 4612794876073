<script setup>
import { PopoverButton } from "@headlessui/vue";

defineProps({
  navigation: {
    type: Array,
    required: true,
  },
});

const user = await getCurrentUser();
const userStore = useUserStore();
const homeStore = useHomeStore();
const dashboardLoaded = useState("dashboard:loaded");
onMounted(() => {
  dashboardLoaded.value = true;
});
</script>

<template>
  <nav
    class="relative flex items-center justify-between px-4 mx-auto max-w-7xl sm:px-6"
    aria-label="Global">
    <div class="flex items-center flex-1">
      <div class="flex items-center justify-between w-full md:w-auto">
        <NuxtLink to="/">
          <span class="sr-only">VimiCrypto Logo</span>
          <VimiLogo class="w-auto h-20" />
        </NuxtLink>
        <div class="flex md:hidden">
          <UButton
            v-if="userStore.signedIn && user.emailVerified"
            to="/system"
            icon="i-material-symbols-arrow-right-alt-rounded"
            :trailing="true"
            :loading="!dashboardLoaded"
            @click="dashboardLoaded = false">
            Dashboard
          </UButton>
          <NuxtLink
            v-else
            to="/login"
            @click="homeStore.signIn = true"
            class="text-sm font-semibold leading-6 text-white">
            Log in<UIcon
              name="material-symbols:arrow-right-alt-rounded"
              class="w-4 h-5" />
          </NuxtLink>
        </div>

        <div class="flex items-center -mr-2 md:hidden">
          <PopoverButton
            class="inline-flex items-center justify-center p-2 text-gray-400 bg-gray-800 rounded-md focus-ring-inset hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-white">
            <span class="sr-only">Open main menu</span>
            <UIcon
              name="material-symbols:menu"
              class="w-6 h-6"
              aria-hidden="true" />
          </PopoverButton>
        </div>
      </div>

      <!-- Menu Items horizontal (Larger screens) -->
      <div class="hidden space-x-10 md:ml-10 md:flex">
        <NuxtLink
          v-for="item in navigation"
          :key="item.name"
          :href="item.href"
          class="font-medium text-white hover:text-gray-300"
          >{{ item.name }}</NuxtLink
        >
      </div>
    </div>
    <div class="hidden md:flex">
      <UButton
        v-if="userStore.signedIn && user.emailVerified"
        to="/system"
        icon="i-material-symbols-arrow-right-alt-rounded"
        :trailing="true"
        :loading="!dashboardLoaded"
        @click="dashboardLoaded = false">
        Go to Dashboard
      </UButton>

      <NuxtLink
        v-else
        to="/login"
        @click="homeStore.signIn = true"
        class="text-sm font-semibold leading-6 text-white">
        Log in
        <UIcon
          name="material-symbols:arrow-right-alt-rounded"
          class="w-4 h-5" />
      </NuxtLink>
    </div>
  </nav>
</template>

<style lang="scss" scoped></style>
